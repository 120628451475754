import { Injectable } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { SortData } from '../_models/common/sortData';
import { OrderType } from '../_models/enums/orderType';
import { TableType } from '../_models/enums/tableType';

@Injectable({ providedIn: 'root' })
export class LocalStorageSortService {
  public getSortData(tableType: TableType): SortData {
    let sortData: SortData;
    const jsonSortData = localStorage.getItem(
      localStorage.getItem('user') + '_' + tableType.toString()
    );
    sortData = JSON.parse(jsonSortData) as SortData;
    switch (tableType) {
      case TableType.HomeLoads:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'loadNumber';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.ManageTrucks:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'truckNumber';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.ManageTrailers:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'trailerNumber';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.ManageDrivers:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'driverName';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.ManageDispatchers:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'dispatcherName';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.ManageCustomers:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'customerName';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.PayrollDrivers:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'driverFullName';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.PayrollLoan:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'date';
          sortData.order = OrderType.Desc.toString();
        }
        return sortData;
      case TableType.PayrollEscrow:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'date';
          sortData.order = OrderType.Desc.toString();
        }
        return sortData;
      case TableType.PayrollNotes:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'date';
          sortData.order = OrderType.Desc.toString();
        }
        return sortData;
      case TableType.PayrollPrevious:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'dateFrom';
          sortData.order = OrderType.Desc.toString();
        }
        return sortData;
      case TableType.AccountingMain:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'date';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.AnalyticsTruck:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'truckNumber';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.AnalyticsCustomer:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'customerName';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.PayrollRecent:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'payrollDateTo';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.AdvancedSearch:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'loadNumber';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.SettingsAdmins:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'adminName';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      case TableType.SettingsTransactions:
        if (!sortData) {
          sortData = new SortData();
          sortData.activeColumn = 'created';
          sortData.order = OrderType.Asc.toString();
        }
        return sortData;
      default:
        break;
    }
  }

  public setSortData(tableType: TableType, sort: MatSort) {
    if (sort?.active) {
      let sortData = new SortData();
      sortData.activeColumn = sort?.active;
      sortData.order = sort?.direction;
      localStorage.setItem(
        localStorage.getItem('user') + '_' + tableType.toString(),
        JSON.stringify(sortData)
      );
    }
  }
}
