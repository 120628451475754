export const environment = {
  production: false,
  auth0: {
    domain: 'mytruckboard.us.auth0.com',
    clientId: '2WbkY2wUpLQ4QPVajuDUpcodGaXLkPUr',
    redirectUri: window.location.origin,
    audience: 'https://api.mytruckboard.com',
  },
  apiUrl: 'https://api.mytruckboard.net/api/v1',
  stripeAddNewUrl: 'https://buy.stripe.com/28o4k8eIzdQL8py4gg',
  clearLocalStorage: [],
  appVersion: '2.06.25.0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
