<mat-list-item>
  <h3 class="displayLang">{{ "SETTINGS.DISPLAY_LANG" | translate }}:</h3>
  <select
    class="langSelect"
    #langselect
    [(ngModel)]="selectedLanguage"
    (change)="translate.use(langselect.value); changeLanguage(langselect.value)"
  >
    <option class="option" value="en">{{ "SETTINGS.ENG" | translate }}</option>
    <option class="option" value="fr">{{ "SETTINGS.FR" | translate }}</option>
    <option class="option" value="es">{{ "SETTINGS.ESP" | translate }}</option>
  </select>
</mat-list-item>
