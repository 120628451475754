import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AnalyticsCustomerComponent } from './analytics/analytics-customer/analytics-customer.component';
import { AnalyticsTruckComponent } from './analytics/analytics-truck/analytics-truck.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddressListComponent } from './common/address-list/address-list.component';
import { CommentListComponent } from './common/comment-list/comment-list.component';
import { AuthenticationButtonComponent } from './components/authentication-button/authentication-button.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { SignupButtonComponent } from './components/signup-button/signup-button.component';
import { DriverApplicationComponent } from './driver-application/driver-application.component';
import { HomeComponent } from './home/home.component';
import { AccountingComponent } from './loads/accounting.component';
import { ConfirmDialogChangeStatusComponent } from './loads/confirm-dialog-change-status/confirm-dialog-change-status.component';
import { DownloadRcComponent } from './loads/download-rc/download-rc.component';
import { LoadsCommentComponent } from './loads/loads-comment-dialog/loads-comment.component';
import { AddCustomerComponent } from './loads/new-load/add-customer/add-customer.component';
import { EditDeadheadDialogComponent } from './loads/new-load/edit-deadhead-dialog/edit-deadhead-dialog.component';
import { EditMilageDialogComponent } from './loads/new-load/edit-milage-dialog/edit-milage-dialog.component';
import { LoadCommentDialogComponent } from './loads/new-load/load-comment-dialog.component';
import { LoadComponent } from './loads/new-load/load.component';
import { PrintInvoiceDialogComponent } from './loads/new-load/print-invoice-dialog/print-invoice-dialog.component';
import { ReplaceLoadDocumentComponent } from './loads/new-load/replace-load-document/replace-load-document.component';
import { StopAddressComponent } from './loads/new-load/stop-address/stop-address.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ErrorInterceptor, SpinnerInterceptor } from './_helpers';
import { ColorSpanTodayPipe } from './_helpers/pipes/color.span.today.date.pipe';
import { SpanTodayPipe } from './_helpers/pipes/span.today.date.pipe';
import { TenantDatePipe } from './_helpers/pipes/tenant.date.pipe';
import { CodeFactory } from './_services/factory/code.factory.service';
import { DocumentFactory } from './_services/factory/document.factory.service';
import { RoleFactory } from './_services/factory/role.factory.service';
import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from './common/info-dialog/info-dialog.component';
import { AdvancedSearchComponent } from './loads/advanced-search/advanced-search.component';
import { AddEditDialogComponent } from './loads/crud-accounting/add-edit-dialog/add-edit-dialog.component';
import { ComfirmDeleteDialogComponent } from './loads/crud-accounting/comfirm-delete-dialog/comfirm-delete-dialog.component';
import { ConfirmAddDialogComponent } from './loads/crud-accounting/confirm-add-dialog/confirm-add-dialog.component';
import { EmailDocumentsComponent } from './loads/email-documents/email-documents.component';
import { ConfirmDeleteLoadDocumentComponent } from './loads/new-load/confirm-delete-load-document/confirm-delete-load-document.component';
import { QuickLoadDialogComponent } from './loads/quick-load-dialog/quick-load-dialog.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { ComfirmDialogCustomerComponent } from './manage/customer/comfirm-dialog-customer/comfirm-dialog-customer.component';
import { CustomerComponent } from './manage/customer/customer.component';
import { EditCustomerDialogComponent } from './manage/customer/edit-customer-dialog/edit-customer-dialog.component';
import { DispatchersComponent } from './manage/dispatchers/dispatchers.component';
import { ComfirmDialogDispatcherComponent } from './manage/dispatchers/edit-delete-dispatcher/comfirm-dialog-dispatcher/comfirm-dialog-dispatcher.component';
import { EditDispatcherDialogComponent } from './manage/dispatchers/edit-delete-dispatcher/edit-dispatcher-dialog/edit-dispatcher-dialog.component';
import { DriversComponent } from './manage/drivers/drivers.component';
import { ComfirmDialogDriverComponent } from './manage/drivers/edit-delete-driver/comfirm-dialog-driver/comfirm-dialog-driver.component';
import { EditDriverDialogComponent } from './manage/drivers/edit-delete-driver/edit-driver-dialog/edit-driver-dialog.component';
import { ManageComponent } from './manage/manage.component';
import { SafetyInfoComponent } from './manage/safety-info/safety-info.component';
import { ComfirmDialogTrailerComponent } from './manage/trailers/edit-delete-trailer/comfirm-dialog-trailer/comfirm-dialog-trailer.component';
import { EditTrailerDialogComponent } from './manage/trailers/edit-delete-trailer/edit-trailer-dialog/edit-trailer-dialog.component';
import { TrailersComponent } from './manage/trailers/trailers.component';
import { ComfirmDialogComponent } from './manage/trucks/edit-delete-truck/comfirm-dialog/comfirm-dialog.component';
import { EditTruckDialogComponent } from './manage/trucks/edit-delete-truck/edit-truck-dialog/edit-truck-dialog.component';
import { TrucksComponent } from './manage/trucks/trucks.component';
import { AddAdditionalCostDialogComponent } from './payroll/add-additional-cost-dialog/add-additional-cost-dialog.component';
import { AddPayrollCommentComponent } from './payroll/add-payroll-comment/add-payroll-comment.component';
import { AmountItemComponent } from './payroll/amount-item/amount-item.component';
import { ConfirmDeleteEscrowDialogComponent } from './payroll/confirm-delete-escrow-dialog/confirm-delete-escrow-dialog.component';
import { ConfirmDeleteLoanDialogComponent } from './payroll/confirm-delete-loan-dialog/confirm-delete-loan-dialog.component';
import { DeletePayrollConfirmPopupComponent } from './payroll/delete-payroll-confirm-popup/delete-payroll-confirm-popup.component';
import { PayCalculationConfirmDialogComponent } from './payroll/pay-calculation-confirm-dialog/pay-calculation-confirm-dialog.component';
import { PayrollPdfPreviewPopupComponent } from './payroll/payroll-pdf-preview-popup/payroll-pdf-preview-popup.component';
import { PayrollCalcComponent } from './payroll/payroll-spec/payroll-calc/payroll-calc.component';
import { PayrollEscrowComponent } from './payroll/payroll-spec/payroll-escrow/payroll-escrow.component';
import { PayrollLoanComponent } from './payroll/payroll-spec/payroll-loan/payroll-loan.component';
import { PayrollNotesComponent } from './payroll/payroll-spec/payroll-notes/payroll-notes.component';
import { PayrollPreviewComponent } from './payroll/payroll-spec/payroll-preview/payroll-preview.component';
import { PayrollSpecComponent } from './payroll/payroll-spec/payroll-spec.component';
import { PreviousPayrollsComponent } from './payroll/payroll-spec/previous-payrolls/previous-payrolls.component';
import { PayrollComponent } from './payroll/payroll.component';
import { PayrollsComponent } from './payroll/payrolls/payrolls.component';
import { RecentPayrollsComponent } from './payroll/recent-payrolls/recent-payrolls.component';
import { AddOtherComponent } from './settings/add-other/add-other.component';
import { ConfirmChangeTimezoneComponent } from './settings/company-data/confirm-change-timezone/confirm-change-timezone.component';
import { TenantComponent } from './settings/company-data/tenant.component';
import { UploadLogoComponent } from './settings/company-data/upload-logo/upload-logo.component';
import { SettingsComponent } from './settings/settings.component';
import { TransactionsBilingComponent } from './settings/transactions-biling/transactions-biling.component';
import { AdminsComponent } from './settings/users/administrators/admins.component';
import { ChangePassDialogComponent } from './settings/users/administrators/change-pass-dialog/change-pass-dialog.component';
import { EditAdminDialogComponent } from './settings/users/administrators/edit-admin-dialog/edit-admin-dialog.component';
import { UsersComponent } from './settings/users/users.component';
import { SelectLangComponent } from './user-settings/select_lang/select_lang.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    AccountingComponent,
    ManageComponent,
    TrucksComponent,
    DriversComponent,
    DispatchersComponent,
    SafetyInfoComponent,
    ComfirmDialogComponent,
    LoadComponent,
    LoadCommentDialogComponent,
    StopAddressComponent,
    ComfirmDialogDriverComponent,
    ComfirmDialogDispatcherComponent,
    AddressListComponent,
    EditTruckDialogComponent,
    AnalyticsComponent,
    EditDriverDialogComponent,
    EditDispatcherDialogComponent,
    SettingsComponent,
    UsersComponent,
    AddOtherComponent,
    SelectLangComponent,
    LoginButtonComponent,
    SignupButtonComponent,
    AuthenticationButtonComponent,
    ReplaceLoadDocumentComponent,
    TrailersComponent,
    ComfirmDialogTrailerComponent,
    EditTrailerDialogComponent,
    VerifyAccountComponent,
    TenantDatePipe,
    SpanTodayPipe,
    ColorSpanTodayPipe,
    CustomerComponent,
    EditCustomerDialogComponent,
    ComfirmDialogCustomerComponent,
    PayrollsComponent,
    PayrollSpecComponent,
    PayrollCalcComponent,
    PayrollLoanComponent,
    PayrollEscrowComponent,
    PayrollNotesComponent,
    PayrollPreviewComponent,
    PreviousPayrollsComponent,
    AmountItemComponent,
    HomeComponent,
    ConfirmDialogChangeStatusComponent,
    AddAdditionalCostDialogComponent,
    PayCalculationConfirmDialogComponent,
    AddPayrollCommentComponent,
    DeletePayrollConfirmPopupComponent,
    PayrollPdfPreviewPopupComponent,
    DriverApplicationComponent,
    UserSettingsComponent,
    ConfirmDeleteLoanDialogComponent,
    ConfirmDeleteEscrowDialogComponent,
    TenantComponent,
    EditMilageDialogComponent,
    EditDeadheadDialogComponent,
    LoadsCommentComponent,
    CommentListComponent,
    UploadLogoComponent,
    PrintInvoiceDialogComponent,
    AnalyticsTruckComponent,
    AnalyticsCustomerComponent,
    DownloadRcComponent,
    AddCustomerComponent,
    QuickLoadDialogComponent,
    ConfirmDeleteLoadDocumentComponent,
    EmailDocumentsComponent,
    AddEditDialogComponent,
    ComfirmDeleteDialogComponent,
    ConfirmAddDialogComponent,
    ConfirmChangeTimezoneComponent,
    PayrollComponent,
    RecentPayrollsComponent,
    AdvancedSearchComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    AdminsComponent,
    EditAdminDialogComponent,
    ChangePassDialogComponent,
    TransactionsBilingComponent,
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    A11yModule,
    PortalModule,
    ScrollingModule,
    OverlayModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatTableModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatDialogModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSortModule,
    DragDropModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatCardModule,
    MatTabsModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxExtendedPdfViewerModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxFileDropModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxSpinnerModule,
    // 👇 add and initialize AuthModule
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: environment.auth0.redirectUri,
        audience: environment.auth0.audience,
      },
      httpInterceptor: {
        allowedList: [
          {
            uriMatcher: (uri: string) => {
              if (uri.includes('activation')) {
                return false;
              }
              if (uri.includes('assets')) {
                return false;
              }
              return true;
            },
          },
        ],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    DocumentFactory,
    CodeFactory,
    RoleFactory,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
