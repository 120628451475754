export enum TableType {
  HomeLoads = 'HomeLoads',
  ManageTrucks = 'ManageTrucks',
  ManageTrailers = 'ManageTrailers',
  ManageDrivers = 'ManageDrivers',
  ManageDispatchers = 'ManageDispatchers',
  ManageCustomers = 'ManageCustomers',
  PayrollDrivers = 'PayrollDrivers',
  PayrollLoan = 'PayrollLoan',
  PayrollEscrow = 'PayrollEscrow',
  PayrollNotes = 'PayrollNotes',
  PayrollPrevious = 'PayrollPrevious',
  PayrollRecent = 'PayrollRecent',
  AccountingMain = 'AccountingMain',
  AnalyticsTruck = 'AnalyticsTruck',
  AnalyticsCustomer = 'AnalyticsCustomer',
  AdvancedSearch = 'AdvancedSearch',
  SettingsAdmins = 'SettingsAdmins',
  SettingsTransactions = 'SettingsTransactions',
}
